








































import { computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { useIntegrations } from '~/composables';
import { BloomreachProduct } from '~/types/product/BloomreachProduct';
import { LinkType } from '~/types/components/Link/LinkType';
import Link from '~/components/atoms/Link/Link.vue';
import { cleanBloomreachImageUrl, prepareBloomreachProductPath } from '~/helpers/bloomreach/bloomreachData';
import {
  prepareProductBoxClickEventData
} from '~/helpers/prepareProductBoxClickEventData/prepareProductBoxClickEventData';
import TwicpicsImage from '~/components/atoms/TwicpicsImage/TwicpicsImage.vue';
import { SIMPLIFIED_CARD } from '~/constants/productCard';
import type { EnergyRatingDetails } from '~/types/components/EnergyRating/EnergyRatingDetails';
import { ENERGY_RATING_SIZE } from '~/constants/energyRating';

export default defineComponent({
  name: 'BloomreachProductCart',
  components: {
    Link,
    TwicpicsImage,
    EnergyRating: () => import(/* webpackChunkName: "EnergyRating" */
      '~/components/molecules/Product/EnergyRating.vue')
  },
  props: {
    product: {
      type: Object as PropType<BloomreachProduct>,
      required: true
    },
    productBoxTypeTag: {
      type: String,
      required: true
    },
    energyRating: {
      type: Object as PropType<EnergyRatingDetails>,
      default: () => ({} as EnergyRatingDetails)
    }
  },
  setup(props) {
    const cleanedImageUrl = computed(() => cleanBloomreachImageUrl(props.product.image));
    const productUrlPath = computed(() => prepareBloomreachProductPath(props.product.url));
    const price = computed(() => {
      return Number.isInteger(props.product.price_local_currency)
        ? props.product.price_local_currency + '.00'
        : props.product.price_local_currency;
    });
    const isDiscounted = computed(() => {
      return props.product.discount_value_local_currency > 0;
    });
    const showEnergyRating = computed(() => {
      return !!props.energyRating?.rating;
    });

    const { $tagManager } = useIntegrations();

    const handleCardClick = () => {
      $tagManager.events.triggerClickProductBoxTags({
        boxType: props.productBoxTypeTag,
        product: prepareProductBoxClickEventData(props.product)
      });
    };

    return {
      cleanedImageUrl,
      productUrlPath,
      LinkType,
      SIMPLIFIED_CARD,
      price,
      isDiscounted,
      showEnergyRating,
      ENERGY_RATING_SIZE,
      handleCardClick
    };
  }
});
