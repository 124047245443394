import { BloomreachProduct } from '~/types/product/BloomreachProduct';
import { ProductBoxData } from '~/types/integrations/tagManager/TagProductBox';

export const prepareProductBoxClickEventData = (product: BloomreachProduct): ProductBoxData => {
  return {
    id: product.item_id,
    name: product.title,
    currency: product.local_currency,
    discountedPrice: product.discount_value_local_currency,
    brand: product.brand,
    price: product.price_local_currency,
    categories: product.category_path
  };
};
